<script lang="ts">
  export let size: number = 24;
</script>

<svg width={size} height={size} viewBox="0 0 24 24">
  <path
    fill="currentColor"
    d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
  />
</svg>
