const locationIconMap = new Map([
    ['PLANET', '🌍'],
    ['MOON', '🌕'],
    ['ASTEROID', '🌑'],
    ['GAS_GIANT', '🪐'],
]);
export const getLocationIcon = (type) => {
    return locationIconMap.get(type) || '🌌';
};
const itemIconMap = new Map([
    ['DRONES', '🛩️'],
    ['MACHINERY', '⚙️'],
    ['FUEL', '⛽'],
    ['FUSIION_REACTORS', '☢️'],
    ['RESEARCH', '🔬'],
    ['TEXTILES', '👗'],
    ['FOOD', '🍖'],
    ['CONSTRUCTION_MATERIALS', '🧱'],
    ['UNSTABLE_COMPOUNDS', '🧪'],
    ['CHEMICALS', '🧪'],
    ['EXPLOSIVES', '🧨'],
    ['NARCOTICS', '💊'],
    ['ELECTRONICS', '🔌'],
    ['BIOMETRIC_FIREARMS', '🔫'],
    ['NANOBOTS', '🤖'],
    ['SHIP_PLATING', '🛡️'],
]);
export const getItemIcon = (symbol) => {
    return itemIconMap.get(symbol) || '📦';
};
