import { updateUser } from './user';
export const getMarketplace = async ({ accessToken }, location) => {
    const res = await fetch(`https://api.spacetraders.io/game/locations/${location}/marketplace`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    switch (res.status) {
        case 200: {
            return (await res.json()).location;
        }
        default: {
            throw new Error(await res.text());
        }
    }
};
export const buyMarketItem = async (userData, shipId, item, quantity) => {
    const { accessToken, user } = userData;
    const res = await fetch(`https://api.spacetraders.io/users/${user.username}/purchase-orders?shipId=${shipId}&good=${item}&quantity=${quantity}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    switch (res.status) {
        case 201: {
            await updateUser(userData);
            return null;
        }
        default: {
            return (await res.json()).error.message || 'unknown error occurred';
        }
    }
};
export const sellMarketItem = async (userData, shipId, item, quantity) => {
    const { accessToken, user } = userData;
    const res = await fetch(`https://api.spacetraders.io/users/${user.username}/sell-orders?shipId=${shipId}&good=${item}&quantity=${quantity}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    switch (res.status) {
        case 201: {
            await updateUser(userData);
            return null;
        }
        default: {
            return (await res.json()).error.message || 'unknown error occurred';
        }
    }
};
