export const getSystems = async ({ accessToken, }) => {
    const res = await fetch('https://api.spacetraders.io/game/systems', {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    switch (res.status) {
        case 200: {
            return (await res.json()).systems;
        }
        default: {
            throw new Error(await res.text());
        }
    }
};
export const getLocation = async ({ accessToken }, location) => {
    const res = await fetch(`https://api.spacetraders.io/game/locations/${location}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    switch (res.status) {
        case 200: {
            return (await res.json()).location;
        }
        default: {
            throw new Error(await res.text());
        }
    }
};
export const getDockedShips = async ({ accessToken }, location) => {
    const res = await fetch(`https://api.spacetraders.io/game/locations/${location}/ships`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    switch (res.status) {
        case 200: {
            return (await res.json()).ships;
        }
        default: {
            throw new Error(await res.text());
        }
    }
};
