import { updateUser } from './user';
export const getLoans = async ({ accessToken, }) => {
    const res = await fetch('https://api.spacetraders.io/game/loans', {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    switch (res.status) {
        case 200: {
            return (await res.json()).loans;
        }
        default: {
            throw new Error(await res.text());
        }
    }
};
export const takeLoan = async (userData, type) => {
    const { accessToken, user } = userData;
    const res = await fetch(`https://api.spacetraders.io/users/${user.username}/loans?type=${type}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    switch (res.status) {
        case 201: {
            await updateUser(userData);
            break;
        }
        default: {
            throw new Error(await res.text());
        }
    }
};
export const payLoan = async (userData, loanId) => {
    const { accessToken, user } = userData;
    const res = await fetch(`https://api.spacetraders.io/users/${user.username}/loans/${loanId}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    switch (res.status) {
        case 200: {
            await updateUser(userData);
            break;
        }
        default: {
            throw new Error(await res.text());
        }
    }
};
