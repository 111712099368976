import { userData } from '../stores';
export const updateUser = async ({ accessToken, user, }) => {
    const res = await fetch(`https://api.spacetraders.io/users/${user.username}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    switch (res.status) {
        case 200: {
            const { user } = (await res.json());
            userData.set({
                user,
                accessToken,
            });
            break;
        }
        default: {
            throw new Error(await res.text());
        }
    }
};
