export const getLeaderBoard = async ({ accessToken, }) => {
    const res = await fetch('https://api.spacetraders.io/game/leaderboard/net-worth', {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    switch (res.status) {
        case 200: {
            return (await res.json());
        }
        default: {
            throw new Error(await res.text());
        }
    }
};
