import { writable } from 'svelte/store';
import { getFlightPlan } from './api/flight';
import Home from './pages/Home.svelte';
import dayjs from 'dayjs';
import { updateUser } from './api/user';
export const route = writable({ href: '/', component: Home });
export const userData = writable(null);
export const systems = writable(null);
export const createFlightPlanStore = () => {
    const timeOuts = [];
    const { subscribe, set } = writable(undefined, () => {
        const stop = () => {
            timeOuts.forEach((timeout) => clearTimeout(timeout));
        };
        return stop;
    });
    const reset = () => set(undefined);
    const updateOnArrival = (userData, flightPlan) => {
        let timeRemaining = dayjs(flightPlan.arrivesAt).diff(dayjs(), 'seconds');
        if (timeRemaining < 0)
            timeRemaining = 0;
        const timeout = setTimeout(async () => {
            await updateUser(userData);
            reset();
        }, timeRemaining * 1000);
        timeOuts.push(timeout);
    };
    const update = (userData, flightPlan) => {
        updateOnArrival(userData, flightPlan);
        set(flightPlan);
    };
    const fetch = async (userData, shipId) => {
        const flightPlan = await getFlightPlan(userData, shipId);
        if (flightPlan) {
            updateOnArrival(userData, flightPlan);
            set(flightPlan);
        }
    };
    return {
        subscribe,
        fetch,
        update,
        reset,
    };
};
