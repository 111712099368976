import { updateUser } from './user';
export const getShips = async ({ accessToken }, _class) => {
    let url = 'https://api.spacetraders.io/game/ships';
    if (_class)
        url += `?class=${_class}`;
    const res = await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    switch (res.status) {
        case 200: {
            return (await res.json()).ships;
        }
        default: {
            throw new Error(await res.text());
        }
    }
};
export const getShip = async ({ accessToken, user }, shipId) => {
    const res = await fetch(`https://api.spacetraders.io/users/${user.username}/ships/${shipId}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    switch (res.status) {
        case 200: {
            return (await res.json()).ship;
        }
        case 404: {
            return null;
        }
        default: {
            throw new Error(await res.text());
        }
    }
};
export const buyShip = async (userData, location, type) => {
    const { accessToken, user } = userData;
    const res = await fetch(`https://api.spacetraders.io/users/${user.username}/ships?location=${location}&type=${type}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    switch (res.status) {
        case 201: {
            await updateUser(userData);
            break;
        }
        default: {
            throw new Error(await res.text());
        }
    }
};
export const jettisonCargo = async (userData, shipId, good, quantity) => {
    const { accessToken, user } = userData;
    const res = await fetch(`https://api.spacetraders.io/users/${user.username}/ships/${shipId}/jettison?good=${good}&quantity=${quantity}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    switch (res.status) {
        case 200: {
            await updateUser(userData);
            return null;
        }
        default: {
            return (await res.json()).error.message || 'unknown error occurred';
        }
    }
};
export const scrapShip = async (userData, shipId) => {
    const { accessToken, user } = userData;
    const res = await fetch(`https://api.spacetraders.io/users/${user.username}/ships/${shipId}/`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    switch (res.status) {
        case 200: {
            await updateUser(userData);
            break;
        }
        default: {
            throw new Error(await res.text());
        }
    }
};
